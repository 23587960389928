// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { get } from 'lodash';

// Components
import Layout from '../components/layout';
import ActionPanel from '../components/action-panel';

// Utils
import ComponentMap from '../utils/component-map';
import SeoUtils from '../utils/seo-utils';

import '../styles/pages/carriers.scss';

const Carriers = ({ data }) => {
  const siteTitle = get(data, 'datoCmsCarrierPage.carrierLandingPageTitle');
  const queryData = get(data, 'datoCmsCarrierPage.carrierModule');
  const statesData = get(data, 'allDatoCmsState');

  // Seo
  const seoTags = get(data, 'datoCmsCarrierPage.seoMetaTags.tags');
  const seoProps = {
    description: SeoUtils.extractProperty(seoTags, 'name', 'description'),
    twitterCardType: SeoUtils.extractProperty(seoTags, 'name', 'twitter:card'),
    cardImage: SeoUtils.extractProperty(seoTags, 'property', 'og:image'),
  };

  // Render components based on module
  const RenderComponents = Array.isArray(queryData)
    ? queryData.map((eacModule) => {
        const label = get(eacModule, 'model.apiKey');
        const isBanner = label === 'hero';
        const ComponentProps = {
          data: eacModule,
          ...(isBanner && {
            states: statesData,
            showInputArea: true,
            showCarrierFilter: true,
          }),
        };
        const eachComponent = ComponentMap(label, ComponentProps, eacModule.id);
        return eachComponent;
      })
    : [];

  return (
    <div>
      <Layout title={siteTitle} seoProps={seoProps}>
        {RenderComponents}
        <ActionPanel />
      </Layout>
    </div>
  );
};

Carriers.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default Carriers;

export const pageQuery = graphql`
  query carrierQuery {
    allDatoCmsState {
      edges {
        node {
          id
          stateTitle
          stateSlug
        }
      }
    }
    datoCmsCarrierPage {
      id
      carrierLandingPageTitle
      seoMetaTags {
        tags
      }
      carrierModule {
        ... on DatoCmsHero {
          id
          heroDesktopImage {
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          heroMobileImage {
            url
            gatsbyImageData(placeholder: BLURRED)
          }
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsIntro {
          id
          introList {
            stateIntroName
            introHeading
            introDescription {
              id
              description
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContact {
          id
          contactList {
            contactPromoList {
              titleAndDescription
              callUsTitle
              contactUsNumber
              contactEmailField
              contactEmail
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsStatsContent {
          id
          statsItem {
            statsName
            statDescription
            statsItems {
              id
              statsInfo
              statsDescription
              statsColor {
                hex
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsStatePromo {
          id
          statePromoTitle
          statePromoList {
            id
            stateTitle
            stateSlug
            categoryFilter {
              carriers {
                carrierTitle
                carrierLogo {
                  url
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
              lineFilter {
                carrierLineCategoryTitle
                originalId
                selectCarrierCategory {
                  originalId
                  carrierCategoryName
                }
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFeaturedArticle {
          id
          featuredArticle {
            ... on DatoCmsArticle {
              id
              title
              subtitle
              slug
              views
              originalId
              featuredImage {
                url
              }
              selectArticleTagCategory {
                articleTagTitle
                slug
              }
              meta {
                createdAt(formatString: "DD MMM YYYY")
              }
            }
            ... on DatoCmsBlog {
              id
              title
              subtitle
              slug
              views
              originalId
              featuredImage {
                url
              }
              selectArticleTagCategory {
                articleTagTitle
                slug
              }
              meta {
                createdAt(formatString: "DD MMM YYYY")
              }
            }
            ... on DatoCmsWebinar {
              id
              title
              subtitle
              slug
              views
              originalId
              featuredImage {
                url
              }
              selectArticleTagCategory {
                articleTagTitle
                slug
              }
              meta {
                createdAt(formatString: "DD MMM YYYY")
              }
            }
            ... on DatoCmsPressRelease {
              id
              title
              subtitle
              slug
              views
              originalId
              featuredImage {
                url
              }
              selectArticleTagCategory {
                articleTagTitle
                slug
              }
              meta {
                createdAt(formatString: "DD MMM YYYY")
              }
            }
            ... on DatoCmsOnDemandTraining {
              id
              title
              subtitle
              slug
              views
              originalId
              featuredImage {
                url
              }
              selectArticleTagCategory {
                articleTagTitle
                slug
              }
              meta {
                createdAt(formatString: "DD MMM YYYY")
              }
            }
            ... on DatoCmsAgencyGuide {
              id
              title
              subtitle
              slug
              views
              originalId
              featuredImage {
                url
              }
              selectArticleTagCategory {
                articleTagTitle
                slug
              }
              meta {
                createdAt(formatString: "DD MMM YYYY")
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSignPostCenteredList {
          id
          signPostCenteredList {
            id
            heading
            description
            ctaText
            ctaLink
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSignPostSalesList {
          id
          backgroundColor {
            hex
          }
          signPostSalesList {
            id
            salesTitle
            salesDescription
            readyToTalkCtaText
            readyToTalkCtaLink
            getInTouchTitle
            getInTouchDescription
            aboutUsTitle
            signUpDescription
            aboutUsCtaText
            aboutUsLink
            callUsTitle
            callUsNumber
            sendUsMessage
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsWebinarArticleList {
          id
          title
          seeAllCtaLabel
          selectArticle {
            id
            title
            subtitle
            slug
            views
            originalId
            meta {
              publishedAt(formatString: "DD MMMM YYYY")
              updatedAt
            }
            featuredImage {
              url
            }
            model {
              apiKey
            }
            selectArticleTagCategory {
              articleTagTitle
              articleTagSubtitle
              slug
            }
          }
          model {
            apiKey
          }
          backgroundColor {
            hex
          }
        }
        ... on DatoCmsAgencyGuideArticleList {
          id
          title
          seeAllCtaLabel
          selectArticle {
            id
            title
            subtitle
            slug
            views
            originalId
            meta {
              publishedAt(formatString: "DD MMMM YYYY")
              updatedAt
            }
            featuredImage {
              url
            }
            model {
              apiKey
            }
            selectArticleTagCategory {
              articleTagTitle
              articleTagSubtitle
              slug
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsArticleList {
          id
          title
          seeAllCtaLabel
          selectArticle {
            id
            title
            subtitle
            slug
            views
            originalId
            meta {
              publishedAt(formatString: "DD MMMM YYYY")
              updatedAt
            }
            featuredImage {
              url
            }
            model {
              apiKey
            }
            selectArticleTagCategory {
              articleTagTitle
              articleTagSubtitle
              slug
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsBlogArticleList {
          id
          title
          seeAllCtaLabel
          selectArticle {
            id
            title
            subtitle
            slug
            views
            originalId
            meta {
              publishedAt(formatString: "DD MMMM YYYY")
              updatedAt
            }
            featuredImage {
              url
            }
            model {
              apiKey
            }
            selectArticleTagCategory {
              articleTagTitle
              articleTagSubtitle
              slug
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsOnDemandArticleList {
          id
          title
          seeAllCtaLabel
          selectArticle {
            id
            title
            subtitle
            slug
            views
            originalId
            meta {
              publishedAt(formatString: "DD MMMM YYYY")
              updatedAt
            }
            featuredImage {
              url
            }
            model {
              apiKey
            }
            selectArticleTagCategory {
              articleTagTitle
              articleTagSubtitle
              slug
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPressReleaseArticleList {
          id
          title
          seeAllCtaLabel
          selectArticle {
            id
            title
            subtitle
            slug
            views
            originalId
            meta {
              publishedAt(formatString: "DD MMMM YYYY")
              updatedAt
            }
            featuredImage {
              url
            }
            model {
              apiKey
            }
            selectArticleTagCategory {
              articleTagTitle
              articleTagSubtitle
              slug
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFaqAccordion {
          id
          title
          faqList {
            faqList {
              id
              title
              body
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFeaturesWithCta {
          id
          title
          subtitle
          featuresItem {
            id
            featuresList {
              id
              featureIcon {
                url
              }
              featureTitle
              featureDescription
              featureCtaText
              featureCtaLink
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFeaturesWithImage {
          id
          title
          featureBackgroundImage {
            url
          }
          featureItemLink {
            featuresItem {
              id
              featureItemTitle
              featureItemDescription
              itemIcon {
                url
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsLinkPromo {
          id
          linkOption {
            linkPromo {
              id
              title
              description
              link
            }
          }
          model {
            apiKey
          }
          backgroundColor {
            hex
          }
        }
        ... on DatoCmsPartner {
          id
          title
          subTitle
          ctaText
          ctaLink
          carriersLogo {
            id
            carrierLogo {
              url
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED, width: 180)
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPlanCard {
          id
          planLink {
            planTitle
            planDescription
            planIcon {
              alt
              url
            }
            personalLineTitle
            rateTitleForModel
            personalRate {
              personalRateValue
              personalRateCategory
            }
            commercialLinesTitle
            commercialLine {
              commercialRate
              commercialRateCategory
            }
            featureTitleForModal
            featuresList {
              featureAvailable
              featureTitle
              featureValue
            }
            planPrice {
              planPrice
              planDuration
            }
            planCtaText
            planCtaLink
            planDetailCtaText
            modalPlanDetailCtaLink
            modalPlanDetailCtaText
            modalContactUsCtaText
            modalContactUsCtaLink
          }
          planHeaderTitle
          planHeaderDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsRichText {
          id
          addContent
          meta {
            createdAt
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSignPostImageList {
          id
          signPostImageList {
            id
            signTitle
            signSubTitle
            signDecription
            signCtaText
            signCtaLink
            signImage {
              url
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSignPostCenteredList {
          id
          signPostCenteredList {
            id
            heading
            description
            ctaText
            ctaLink
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsSignPostDefaultList {
          id
          signPostDefaultList {
            id
            heading
            ctaText
            ctaLink
          }
          model {
            apiKey
          }
        }
      }
    }
  }
`;
